.landing-page {
    min-height: 80vh;
    padding: 0;
    .signin-section {
        margin-top: 47.12px;
        padding-left: 250px;
        background-image: url('../../media/images/Group.png');
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        @media (max-width: 1602px) {
            padding-left: 20px;
        }
        @media (max-width: 992px) {
            margin-top: 80px;
        }
        .title-text {
            max-width: 470px;
            margin-top: 80px;
            @media (max-width: 602px) {
                margin-top: 25px;
            }
            @media (max-width: 1372px) {
                max-width: 350px;
                width: 45%;
            }
            span {
                color: #FFFFFF;
                font-size: 100.78px;
                letter-spacing: 0;
                line-height: 110%;
            }
        }
        .buttons{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            //height: 117px;
            margin-top: 30px;
            @media (max-width: 992px) {
                margin-top: 15px;
            }
            width: 100%;
            .sign-in-button {
                min-height: 35px;
                min-width: 110px;
                background-color: #FFFFFF;
                border: none;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 64px;
                @media (max-width: 991.98px) {
                    margin-right: 20px;
                }
                span {
                    color: #000000;
                    font-size: 26px;
                    letter-spacing: 0;
                    line-height: 100%;
                    text-align: center;
                    @media (max-width: 991.98px) {
                        font-size: 14px;
                    }
                    &:hover {
                        &:after{
                            content: "";
                            height: 8px;
                            background-color: #000000;
                            display: block;
                            margin: 0;
                            border-radius: 2px;
                        }
                    }
                }
            }
            .sign-up-button {
                min-height: 35px;
                min-width: 110px;
                background-color: #000000;
                border: none;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                span {
                    color: #FFFFFF;
                    font-family: "din-condensed";
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 115%;
                    @media (max-width: 991.98px) {
                        font-size: 11px;
                    }
                }
                p {
                    color: #FFFFFF;
                    font-family: Impact;
                    font-size: 26px;
                    letter-spacing: 0;
                    line-height: 115%;
                    margin-bottom: 2px;
                    @media (max-width: 991.98px) {
                        font-size: 14px;
                    }
                    &:hover {
                        &:after{
                            content: "";
                            height: 8px;
                            background-color: #FFFFFF;
                            display: block;
                            margin: 0;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }
    }
    .imporve-section {
        //height:  771.76px;
        margin-top: 77.62px;
        padding-left: 0;
        background-image: url('../../media/images/Group-1.png');
        //background-size: 1372px auto;
        background-repeat: no-repeat;
        background-position: top left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        margin-bottom: 127.24px;
        @media (max-width: 991.98px) {
            margin-bottom: 50px;
            margin-top: 50px;
        }
        .right-line {
            box-sizing: border-box;
            height: 797px;
            width: 1px;
            border: 2px solid #FFFFFF;
            position: absolute;
            right: 147.5px;
            top: 102px;
            @media (max-width: 1600px) {
                display: none;
            }
        }
        .iframe-div {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            //width: 100%;
            .play-button {
                position: absolute;
                z-index: 1000;
                cursor: pointer;
                border: none;
                box-shadow: none;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 0;
                margin: 0;
                background: transparent;
                img {
                    width: 100%;
                }
            }
            .iframe-player {
                transform: scale(0);
                opacity: 0;
                transition: all .4s ease-in;
                &.hover-effect {
                    transform: scale(1);
                    opacity: 1;
                    transition: tranallsform .5s ease-in .2s;
                }
            }
        }
        .improve-text {
            height: 510.5px;
            width: 470px;
            position: absolute;
            z-index: 1000;
            right: 147.5px;
            top: 102px;
            @media (max-width: 1600px) {
                display: none;
            }
            span {
                color: #FFFFFF;
                font-size: 100.78px;
                letter-spacing: 0;
                line-height: 110px;
            }
        }
        .improve-text-respo {
            //height: 510.5px;
            //max-width: 470px;
            position: absolute;
            z-index: 1000;
            right: 0px;
            top: 50px;
            display: none;
            span {
                color: #FFFFFF;
                font-size: 100.78px;
                letter-spacing: 0;
                line-height: 100%;
            }
            @media (max-width: 1600px) {
                display: flex;
            }
        }
       
       
    }
    .all-videos-section {
        .trhee-images-div {
            position: relative;
            display: flex;
            justify-content: center;
            .first-image {
                position: absolute;
                top: 0;
                left: 0;
                img {
                    width: 100%;
                    position: relative;
                }
                .title-text {
                    position: absolute;
                    right: 0;
                    opacity: 0.7;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    background-color: #000000;
                    z-index: 1600;
                    span {
                        color: #FFFFFF;
                        font-family: "din-condensed";
                        font-size: 28px;
                        letter-spacing: 0;
                        text-align: center;
                    }
                }
                .play-button {
                    width: 111px;
                    position: absolute;
                    z-index: 1600;
                    cursor: pointer;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    margin: 0;
                    background: transparent;
                    img {
                        width: 100%;
                    }
                }
                .mask-first {
                    position: absolute;
                    top: 0;
                    left: -900px;
                    width: 1666px;
                    height: 1186px;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    border-bottom-width: 124px;
                    border-right-width: 93px;
                    border-left-width: 900px;
                    z-index: 200;
                }
                .mask-first-2 {
                    position: absolute;
                    bottom: -1820px;
                    left: -900px;
                    width: 1666px;
                    height: 635px;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    border-right-width: 93px;
                    border-left-width: 1195px;
                    z-index: 200;
                }
                .mask-first-3 {
                    position: absolute;
                    top: 0;
                    right: -100vw;
                    width: calc( 100vw + 377px );
                    height: 1820px;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    border-top-width: 565px;
                    border-right-width: 100vw;
                    border-bottom-width: 617px;
                    z-index: 200;
                }
                .video-div {
                    position: absolute;
                    top: 0;
                    left: -1600px;
                    width: 3254px;
                    opacity: 0;
                    transition: all .4s ease-in;
                    &.hover-effect {
                        opacity: 1;
                        transition: all .3s ease-in;
                    }
                    }
            }
            .second-image {
                position: absolute;
                right: 0;
                img {
                    width: 100%;
                    position: relative;
                }
                .title-text {
                    position: absolute;
                    left: 0;
                    opacity: 0.7;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    background-color: #000000;
                    z-index: 1600;
                    span {
                        height: 28px;
                        color: #FFFFFF;
                        font-family: "din-condensed";
                        font-size: 28px;
                        letter-spacing: 0;
                        line-height: 34px;
                        text-align: center;
                    }
                }
                .play-button {
                    position: absolute;
                    z-index: 1600;
                    cursor: pointer;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    margin: 0;
                    background: transparent;
                    img {
                        width: 100%;
                        position: relative;
                    }
                }
                .mask-first {
                    position: absolute;
                    border: none;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    z-index: 200;
                }
                .mask-first-2 {
                    position: absolute;
                    border: none;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    border-right-width: 0;
                    z-index: 1;
                }
                .mask-first-3 {
                    position: absolute;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    border-top-width: 0px;
                    z-index: 1;
                }
                .video-div {
                    position: absolute;
                    opacity: 0;
                    transition: all .4s ease-in;
                    &.hover-effect {
                        opacity: 1;
                        transition: all .5s ease-in;
                    }
                }
            }
            .third-image {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                img {
                    width: 100%;
                    position: relative;
                    @media (max-width: 1520px) {
                        margin-left: -3px;
                    }
                }
                .title-text {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-48.5%);
                    opacity: 0.7;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    background-color: #000000;
                    z-index: 1600;
                    span {
                        height: 28px;
                        color: #FFFFFF;
                        font-family: "din-condensed";
                        font-size: 28px;
                        letter-spacing: 0;
                        line-height: 34px;
                        text-align: center;
                    }
                }
                .play-button {
                    position: absolute;
                    z-index: 1600;
                    cursor: pointer;
                    border: none;
                    box-shadow: none;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: 0;
                    margin: 0;
                    background: transparent;
                    img {
                        width: 100%;
                    }
                }
                .mask-first-2 {
                    position: absolute;
                    top: 0;
                    border: none;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    z-index: 200;
                }
                .mask-first-3 {
                    position: absolute;
                    top: 0;
                    border-style: solid;
                    border-color: rgb(0, 0, 0);
                    z-index: 200;
                }
                .video-div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: all .4s ease-in;
                    &.hover-effect {
                        opacity: 1;
                        transition: all .3s ease-in;
                    }
                }
            }
            .videos-title {
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2000;
                span {
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 115%;
                    text-align: center;
                }
            }
            .videos-description {
                max-width: 470px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: absolute;
                top: 0;
                right: 215px;
                z-index: 2000;
                @media (max-width: 1600px) {
                    right: 10px;
                    top: -30px;
                    max-width: 35%;
                }
                span {
                    color: #FFFFFF;
                    font-family: "din-condensed";
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 130%;
                }
                @media (max-width: 1080px) {
                    display: none;
                }
            }
        }
        .all-videos-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //height: 261.5px;
            max-width: 909px;
            padding: 0;
            span {
                color: #FFFFFF;
                font-family: "din-condensed";
                font-weight: bold;
                max-width: 90%;
                letter-spacing: 0;
                text-align: center;
            }
            button {
                border: none;
                box-shadow: none;
                width: 80%;
                min-width: 280px;
                min-height: 50px;
                background-color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 50px;
                span {
                    color: #000000;
                    font-family: Impact;
                    font-size: 26px;
                    letter-spacing: 0;
                    line-height: 26px;
                    text-align: center;
                    height: 30px;
                    &:hover {
                        &:after{
                            content: "";
                            height: 8px;
                            background-color: #000000;
                            display: block;
                            margin: 0;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }
    }
    .faq-section {
        margin-bottom: 216px;
        margin-top: 80px;
        @media (max-width: 991.98px) {
            margin-bottom: 80px;
            margin-top: 50px;
        }
        .faq {
            max-width: 1065px;
            padding: 0;
            .faq-title {
                //height: 510.5px;
                max-width: 1065px;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: center;
                }
            }
        }
    }
}