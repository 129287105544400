$brown: #c8866e;


.signup-modal {
  .modal-header {
    padding: 34.5px 34.5px;
    //height: 75px;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-close {
      height: 47px;
      width: 47px;
      background-color: transparent;
      background-image: url('../../media/icons/Close.png');
      background-size: 47px 47px;
      background-repeat: no-repeat;
      background-position: top right;
      padding: 0;
      margin: 0;
    }
  }
  .modal-dialog {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-body {
    padding: 0px 80px 50px 80px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    color: #000000;
    font-size: 64.88px;
    letter-spacing: 0;
    line-height: 70.81px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .modal-text {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.category-modal {
  .modal-content {
    background: #181818;
    border: none;
  }
  .modal-dialog {
    
    margin-right: auto;
    margin-left: auto;
  }
  .modal-body {
    padding: 3rem 2rem;
    position: relative;
    @media (max-width: 810px) {
      padding: 1rem 1rem;
    }

    .modal-title {
      width: 100%;
      color: #FFFFFF;
      text-align: center;
      font-size: 60px;
      font-style: normal;
      line-height: 110%;
      display: flex;
      justify-content: center;
    }
  }
  .modal-close {
    position: absolute;
    background: transparent;
    top: 20px;
    right: 20px;
    box-shadow: none;
    border: none;
    &:hover {
      box-shadow: none;
    border: none;
    }
  }
  .form-outline .form-control ~ .form-label {
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    color: rgba(37, 38, 38, 0.5);
    left: 1.75rem;
  }
  .form-outline .form-control.active ~ .form-label {
   transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
  }
  .form-outline .form-control:focus ~ .form-label {
   transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
  }
  .form-outline .form-control ~ .form-notch {
    .form-notch-leading {
      border-radius: 10px 0 0 10px;
      width: 1.5rem;
      border: none;
      &:focus {
        box-shadow: none!important;
      }
    }
    .form-notch-middle {
      border: none;
      width: 79%!important;
      &:focus {
        box-shadow: none!important;
      }
    }
    .form-notch-trailing {
      border-radius: 0 10px 10px 0;
      border: none;
      &:focus {
        box-shadow: none!important;
      }
    }
  }
  .form-outline .form-control:focus ~ .form-notch {
    .form-notch-leading {
      box-shadow: none!important;
    }
    .form-notch-middle {
      box-shadow: none!important;
    }
    .form-notch-trailing {
      box-shadow: none!important;
    }
  }
  .form-outline .form-control {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
  }
  .form-outline {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 22px 21px;
    border-radius: 10px;
    gap: 10px;
    height: 47px;
    width:28%;
    background: #ffffff;
    backdrop-filter: blur(10px);
    @media (max-width: 995.98px) {
      width:100%;
      max-width: 300px;
    }
  }
  

}

.signin-modal {
  .modal-header {
    padding: 34.5px 34.5px;
    //height: 75px;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-close {
      height: 47px;
      width: 47px;
      background-color: transparent;
      background-image: url('../../media/icons/Close.png');
      background-size: 47px 47px;
      background-repeat: no-repeat;
      background-position: top right;
      padding: 0;
      margin: 0;
    }
  }
  
  .modal-dialog {
    min-height: 804px;
    max-width: 859px;
    margin-right: auto;
    margin-left: auto;
  }

  .modal-content {
    border-radius:0;
    border: none;
  }
  .modal-body {
    padding: 0px 124px 50px 124px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  &.profile-edit {
    .modal-dialog {
      max-width: 1350px;
    }
    .modal-body {
      padding: 0px 80px 50px 80px;
      @media (max-width: 767.98px) {
        padding: 0px 10px 20px 10px;
      }
    }
  }
  .modal-title {
    width: 100%;
    color: #000000;
    font-size: 64.88px;
    letter-spacing: 0;
    line-height: 70.81px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.profile-edit-form {
  .modal-title-form {
    color: #464646;
    font-family: "din-condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */ 
  }
  input {
    box-sizing: border-box;
    height: 68px;
    max-width: 617px;
    width: 100%;
    border: 2px solid #000000;
    border-radius: 0;
    background: transparent; 
    outline: none;
    padding: 18px 17px;
    font-family: "din-condensed";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #000000;
    font-family: "din-condensed";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }

  .input-group {
    //max-width: 535px;
    max-width: 617px;
    width: 100%;
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 68px;
      border-radius: 0;
      border: 2px solid #000000;
      border-left: none;
    }
  }
 
}

.signin-form {
  input {
    box-sizing: border-box;
    height: 68px;
    max-width: 617px;
    width: 100%;
    border: 2px solid #000000;
    border-radius: 0;
    background: transparent; 
    outline: none;
    padding: 18px 17px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }

  &.admin {
    input {
      box-sizing: border-box;
      height: 68px;
      max-width: 617px;
      width: 100%;
      border: 2px solid #000000;
      border-radius: 0;
      background: #ffffff; 
      outline: none;
      padding: 18px 17px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      color: rgba(37, 38, 38);
    
      
    
      & + input {
        margin-top: 10px;
      }
    
      &.error {
        border-color: #c86e6e;
      }
    
      &[readonly],
      &.disabled {
        color: rgba(37, 38, 38, 0.35);
      }
    
     
      
    
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        border-color: #efe2de !important;
        background-color: #efe2de !important;
        -webkit-box-shadow: 0 0 0 50px #efe2de inset;
      }
    }
    label {
      margin-bottom: 5px;
      color: #ffffff;
      font-family: "din-condensed";
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
    }
  
    .input-group {
      //max-width: 535px;
      max-width: 617px;
      width: 100%;
      background: #ffffff; 
      input {
        border-right: none;
      }
      
      .input-group-text {
        height: 68px;
        border-radius: 0;
        border: 2px solid #000000;
        border-left: none;
        color: #000000;
      }
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #000000;
    font-family: "din-condensed";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }

  .input-group {
    //max-width: 535px;
    max-width: 617px;
    width: 100%;
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 68px;
      border-radius: 0;
      border: 2px solid #000000;
      border-left: none;
    }
  }

}

.profile-form {
  .modal-title-form {
    color: #464646;
    font-family: "din-condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */ 
  }
  input {
    box-sizing: border-box;
    height: 68px;
    max-width: 617px;
    width: 100%;
    border: 2px solid #000000;
    border-radius: 0;
    background: transparent; 
    outline: none;
    padding: 18px 17px;
    font-family: "din-condensed";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #000000;
    font-family: "din-condensed";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }

  .input-group {
    //max-width: 535px;
    max-width: 617px;
    width: 100%;
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 68px;
      border-radius: 0;
      border: 2px solid #000000;
      border-left: none;
    }
  }
  
  .rergistration-div {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,-0);
    .rergistration-button {
      border-radius: 30px;
      border: none;
      max-width: 351px;
      width: 40%;
      height: 67px;
      cursor: pointer;
      margin: 5px 10px;
      &.green{
          background: rgba(67, 181, 174, 0.9);
          box-shadow: none;
          span{
            color: #FFF;
            text-align: center;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 133.333% */
            letter-spacing: 2.5px; 
          }
      }
  
      &.black{
          background: #535353;;
          box-shadow: none;
          span{
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              
              text-align: center;
              letter-spacing: 2.5px;
              
              color: #FFFFFF;
              
              flex: none;
              order: 0;
              flex-grow: 0;
          }
      }
      
      &.white{
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
          
          span{
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
  
              text-align: center;
              letter-spacing: 2.5px;
  
              color: rgba(67, 181, 174, 0.9);
  
              flex: none;
              order: 0;
              flex-grow: 0;
          }
      } 
  }
  }
}

.onTouch-modal{
  .modal-dialog {
    max-width: 500px;
  }
  .modal-content {
    background: #171717 !important;
    border-radius: 10px;
    border: 1px solid #FFF;
    //padding-bottom: 25px;
  }
  .modal-body {
    padding: 10px 23px;
    .btn-close {
      box-sizing: content-box;
      background: transparent;
      border: 0;
      color: #ffffff;
      opacity: 1;
      height: auto;
      width: auto;
      padding: 5px;
      margin: 2px;
      //position: absolute;
    }
    .videoTrap {
      width: 100%;
      //height: 353px;
      position: relative;
          .timer {
              position: absolute;
              top: 15px;
              right: 15px;
              z-index: 1500;
              width: 62px;
              height: 62px;
              border-radius: 50%;
              background: #FFFFFF;
              padding-top: 10px;
              img {
                  width: 25px;
                  height: 25px;
              }
              &.pink {
                background: rgba(242, 150, 228, 0.95);
              }
              &.green {
                background: rgba(145, 231, 225, 0.95);
              }
              &.purple {
                background: rgba(167, 145, 231, 0.95);
              }
          }
          .videoitem-image {
            //position: absolute;
            width: 100%;
            height: auto;
            border-radius: 10px;
            //margin: 10px 10px; 
            z-index: 1000;
          }
          .videoitem-promo {
            position: absolute;
            border-radius: 10px;
            //margin: 10px 10px; 
            z-index: 1020;
            opacity: 0;
            transform: scale(0);
            transition: all .4s ease-in-out 0.4s;
            &.show {
                transform: scale(1);
                opacity: 1;
                transition: all .4s ease-in-out 0.4s;
            }
        }
          .title{
              display: flex;
              //height: 17px;
              justify-content: center;
              font-family: 'Impact';
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: 0.05em;
              color: #FFFFFF;
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          .video-time{
              display: flex;
              //width: 167px;
              height: 24px;
              justify-content: center;
              font-family: 'Impact';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.05em;
              color: #FFFFFF;
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          .video-card-layout{
              //position: absolute;
              //top: 315px;
              width: 100%;
              height: 61px;
              background: transparent;
              border-radius: none;
              justify-content: center;
              display: grid;
          }
          .video-card-layout-kedvencek{
              //bottom: 45px;
              //position: absolute;
              width: 100%;
              height: 40px;
              background: transparent;
              border-radius: none;
              justify-content: center;
              display: grid;
              margin: 20px 0 0 0;
              button {
                  padding: 0;
              }
              .fa-2x {
                  font-size: 35px;
              }
              .video-play {
                  width: 35px;
                  height: 35px; 
                  border-radius: 50%;
                  background: #43B5AE;
                  margin-right: 10px;
                  img {
                      margin-left: 3px;
                  }
              }
          }
      
      }
  }
  .videoTrap-position {
    position: absolute;
    top: -105px;
    left: -35px;
    z-index: 3500;
    transform: scale(.5);
    opacity: 0;
    transition: all .5s ease-in-out 0.5s;
    cursor: pointer;
   
    .videoTrap {
        width: 383px;
        height: 353px;
        border-radius: 10px;
        border: 1px solid #FFF;
        background: #171717;
        box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.25);
        .timer {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1000;
            width: 62px;
            height: 62px;
            border-radius: 50%;
            background: #FFFFFF;
            padding-top: 10px;
            img {
                width: 25px;
                height: 25px;
            }
        }
        .videoitem-image {
          //position: absolute;
            width: 100%;
            height: auto;
            border-radius: 10px;
            //margin: 10px 10px; 
            z-index: 1000;
        }
        .title{
            display: flex;
            height: 17px;
            justify-content: center;
            font-family: 'Impact';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        .video-time{
            display: flex;
            //width: 167px;
            height: 24px;
            justify-content: center;
            font-family: 'Impact';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        .video-card-layout{
            width: 100%;
            height: 61px;
            background: transparent;
            border-radius: none;
            justify-content: center;
            display: grid;
        }
        .video-card-layout-kedvencek{
            width: 100%;
            height: 40px;
            background: transparent;
            border-radius: none;
            justify-content: center;
            display: grid;
            button {
                padding: 0;
            }
            .fa-2x {
                font-size: 35px;
            }
            .video-play {
                width: 35px;
                height: 35px; 
                border-radius: 50%;
                background: #43B5AE;
                margin-right: 10px;
                img {
                    margin-left: 3px;
                }
            }
        }
    
    }
}
}

.altalanos-modal {
  .modal-header {
    padding: 34.5px 34.5px;
    //height: 75px;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-close {
      height: 47px;
      width: 47px;
      background-color: transparent;
      background-image: url('../../media/icons/Close.png');
      background-size: 47px 47px;
      background-repeat: no-repeat;
      background-position: top right;
      padding: 0;
      margin: 0;
    }
  }
  
  .modal-dialog {
    min-height: 804px;
    max-width: 859px;
    margin-right: auto;
    margin-left: auto;
  }

  &.profile-edit {
    .modal-dialog {
      max-width: 900px;
    }
  }
  .modal-content {
    border-radius:0;
    border: none;
  }
  .modal-body {
    padding: 0px 124px 50px 124px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  &.profile-edit {
    .modal-dialog {
      max-width: 1000px;
    }
    .modal-body {
      padding: 0px 80px 50px 80px;
      @media (max-width: 767.98px) {
        padding: 0px 10px 20px 10px;
      }
    }
  }
  .modal-title {
    width: 100%;
    color: #000000;
    font-size: 64.88px;
    letter-spacing: 0;
    line-height: 70.81px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.cookies-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgba(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgba(145, 231, 225, 0.9);
  }
  .modal-body {
    padding: 0px 50px 50px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .modal-description {
    color: #767676;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
  .cookie-detail {
    
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 400px;
      padding: 15px 0;
      span {
        color: #767676;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */ 
      }
      .form-switch .form-check-input {
        background-image: none;
        border-width: 0;
        border-radius: .4375rem;
        width: 2rem;
        height: .875rem;
        background-color: rgba(0,0,0,.25);
        margin-top: .3em;
        margin-right: 8px;
        &:checked{
          background-color: rgba(67, 181, 174, 0.9);
        }
      }
      .form-switch .form-check-input::after {
        content: "";
        position: absolute;
        border: none;
        z-index: 2;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        background-color: rgba(0,0,0,.5);
        margin-top: -0.1875rem;
        box-shadow: 0 0px 3px 0 rgba(0,0,0,.07),0 2px 2px 0 rgba(0,0,0,.04);
        transition: background-color .2s,transform .2s;
      }
      .form-check-input[type="checkbox"]:checked::after {
        background: rgba(67, 181, 174, 0.9);
        width: 1.25rem !important;
        height: 1.25rem !important;
        top: 0px;
        left: 0px;
      }
    }
    .detail {
      span {
        color: #767676;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
      }
    }
  }
}
.form-check-input::before {
  position: absolute;
  box-shadow: none;
  border-radius: 50%;
  width: 10px !important;
  height: 18px !important;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  top: 6px;
  left: 15px;
}
.form-check-input[type="checkbox"]:checked::after {
  display: block;
  transform: rotate(45deg);
  border-width: .125rem;
    border-top-width: 0.125rem;
    border-left-width: 0.125rem;
  border-color: #fff;
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
  width: 5px !important;
  height: 10px !important;
  border-style: solid;
    border-top-style: solid;
    border-left-style: solid;
  border-top: 0;
  border-left: 0;
  margin-left: 0;
  margin-top: 0;
  background-color: transparent;
  top: 3px;
  left: 7px;
}
.form-check-input:focus::before {
  opacity: 0;
  box-shadow: none;
}
.form-check-input[type="checkbox"]:focus::after {
  background-color: transparent;
}
.checkbox-div {
  
  input {
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    border: 1px solid #C3CAD9;
    background: #FFF; 
    outline: none;
    padding: 0;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 0;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #5A7184;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
 
}

