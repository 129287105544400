.videocard {
   // height: 495px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;
    //overflow: hidden;
    .number {
        color: #1B1B1B;
        font-family: Impact;
        font-size: 180px;
        letter-spacing: 0;
        line-height: 200px;
        transition: all .3s ease-in 0.2s;
    }  
    .background-img {
        height: 100%;
        width: 70%;
        position: relative;
        transition: all .3s ease-in 0.2s;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
           
        }
        .description {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 22px 12.52px;
            display: inline-flex;
            z-index: 10;
            .description-text {
                letter-spacing: 0;
                line-height: 110%;
                opacity: 1;
                transition: all .3s ease-in 0.2s;
                
                font-size: 4px;

                @media (max-width: 995.98px) {
                    font-size: 26px;
                    line-height: 110%;
                    width: 100%;
                }
            }
        }
        .description-hover {
            position: absolute;
            bottom: 0;
            opacity: 0.7;
            height: 0;
            width: 100%;
            background-color: #000000;
            padding-left: 10px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            transition: all .3s ease-in 0.2s;
            z-index: 50;
            @media (max-width: 995.98px) {
                padding-left: 10px;
            }
            .trainer-div {
                display: flex;
                position: absolute;
                top: 15px;
                left: 10px;
                align-items: center;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                @media (max-width: 995.98px) {
                    top: 10px;
                    left: 10px;
                }
                img {
                    height: 55px;
                    width: 55px;
                    border-radius: 50%;
                    margin-right: 10px;
                    @media (max-width: 995.98px) {
                        height: 64px;
                        width: 64px;
                        margin-right: 5px;
                    }
                }
                span {
                    color: #FFFFFF;
                    font-family: Impact;
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 31px;
                    text-align: center;
                }
            }
            .play-button {
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                img {
                    height: 35px;
                    width: 35px;
                }
                
            }
            .time {
                align-items: center;
                justify-content: flex-start;
                display: flex;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                @media (max-width: 995.98px) {
                    margin-bottom: 20px;
                }
                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 5px;
                }
                span {
                    color: #FFFFFF;
                    font-family: "din-condensed";
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 34px;
                    text-align: center;
                }
            }
            
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                //margin-bottom: 19.35px;
                .more-text {
                    color: #FFFFFF;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 110%;
                    opacity: 0;
                    
                    transition: all .3s ease-in 0.2s;
                    max-width: 100%;
                    @media (max-width: 995.98px) {
                        font-size: 26px;
                        line-height: 110%;
                        width: 100%;
                    }
                }
                .bookmark-button {
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    height: 48px;
                    margin-right: 25px;
                    position: relative;
                    z-index: 2500;
                    img {
                        height: 22px;
                    }
                }
            }
            
        }
    }
    &:hover {
        transform: scale(1.03);
        transition: all .3s ease-in 0.2s;
    .number {
        color: #1B1B1B;
        text-shadow: -10px 10px 15px rgba(251, 251, 251, 0.3), 0 0 2px rgba(251, 251, 251, 1),
             0 0 2px rgba(251, 251, 251, 1), 0 0 2px rgba(251, 251, 251, 1);
        transition: all .3s ease-in 0.2s;
    }
    .background-img {
        .description {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            span {
                
                opacity: 0.2;
                transition: all .3s ease-in 0.2s;
                
                @media (max-width: 995.98px) {
                    font-size: 26px;
                    line-height: 110%;
                    width: 100%;
                }
            }
        }
        .description-hover {
            height: 100%;
            transition: all .3s ease-in 0.4s;
            .trainer-div {
                opacity: 1;
                transition: all .3s ease-in 0.7s;
            }
            .play-button {
                transition: all .3s ease-in 0.8s;
                opacity: 1;
            }
            .time {
                opacity: 1;
                transition: all .3s ease-in 0.8s;
                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 5px;
                }
            }
            
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .more-text {
                    opacity: 1;
                    transition: all .3s ease-in 0.8s;
                }
                .bookmark-button {
                    opacity: 1;
                    transition: all .3s ease-in 0.8;
                }
            }
            
        }
    }
    }
   
}

.videocard-more {
    //height: 462px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .more-text-respo {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 22px 12.52px;
        color: #FFFFFF;
        font-family: "din-condensed";
        font-size: 22px;
        letter-spacing: 0;
        line-height: 110%;
        opacity: 1;
        transition: all .3s ease-in 0.2s;
        max-width: 100%;
        bottom: 20px;
        text-align: center;
    }
    .background-img {
        height: 100%;
        width: 100%;
        position: relative;
        .thumbnail-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            border-radius: 3px;
        }
        .gymflix-logo {
            position: absolute;
            top:12px;
            left:9px;
            width: 18.56px;
        }
        .description {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 22px 12.52px;
            //transform: translate(-50%, -50%);
            display: flex;
            z-index: 10;
            span {
                letter-spacing: 0;
                line-height: 110%;
                opacity: 1;
                transition: all .3s ease-in 0.2s;
                max-width: 100%;
                font-size: 4px;

                @media (max-width: 995.98px) {
                    font-size: 26px;
                    line-height: 110%;
                    width: 100%;
                }
            }
        }
        .description-hover {
            position: absolute;
            bottom: 0;
            opacity: 0.7;
            height: 0;
            width: 100%;
            background-color: #000000;
            padding-left: 10px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            transition: all .3s ease-in 0.2s;
            z-index: 50;
            @media (max-width: 995.98px) {
                padding-left: 10px;
            }
            .trainer-div {
                display: flex;
                position: absolute;
                top: 5px;
                left: 10px;
                align-items: center;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                @media (max-width: 995.98px) {
                    top: 10px;
                    left: 10px;
                }
                img {
                    height: 55px;
                    width: 55px;
                    border-radius: 50%;
                    margin-right: 10px;
                    @media (max-width: 995.98px) {
                        height: 64px;
                        width: 64px;
                        margin-right: 5px;
                    }
                }
                span {
                    color: #FFFFFF;
                    font-family: Impact;
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 31px;
                    text-align: center;
                }
            }
            .play-button {
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                img {
                    height: 35px;
                    width: 35px;
                }
                
            }
            .time {
                align-items: center;
                justify-content: flex-start;
                display: flex;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                @media (max-width: 995.98px) {
                    margin-bottom: 20px;
                }
                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 5px;
                }
                span {
                    color: #FFFFFF;
                    font-family: "din-condensed";
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 34px;
                    text-align: center;
                }
            }
            
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                //margin-bottom: 19.35px;
                .more-text {
                    color: #FFFFFF;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 110%;
                    opacity: 0;
                    
                    transition: all .3s ease-in 0.2s;
                    max-width: 100%;
                    @media (max-width: 995.98px) {
                        font-size: 26px;
                        line-height: 110%;
                        width: 100%;
                    }
                }
                .bookmark-button {
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    height: 48px;
                    margin-right: 25px;
                    position: relative;
                    z-index: 2500;
                    img {
                        height: 22px;
                    }
                }
            }
            
        }
    }
    &:hover {
        .background-img {
            .description {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                span {
                    
                    opacity: 0.2;
                    transition: all .3s ease-in 0.2s;
                    
                    @media (max-width: 995.98px) {
                        font-size: 26px;
                        line-height: 110%;
                        width: 100%;
                    }
                }
            }
            .description-hover {
                height: 100%;
                transition: all .3s ease-in 0.4s;
                .trainer-div {
                    opacity: 1;
                    transition: all .3s ease-in 0.7s;
                }
                .play-button {
                    transition: all .3s ease-in 0.8s;
                    opacity: 1;
                }
                .time {
                    opacity: 1;
                    transition: all .3s ease-in 0.8s;
                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
                
                .bookmark-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .more-text {
                        opacity: 1;
                        transition: all .3s ease-in 0.8s;
                    }
                    .bookmark-button {
                        opacity: 1;
                        transition: all .3s ease-in 0.8;
                    }
                }
                
            }
        }
    }
    @media (min-width: 1600px) and (max-width: 1919.98px) { 
        .videocard-more {
            height: 452px;
            width: 100%;
            .background-img {
                .description {
                    height: 80px;
                    width: 100%;
                    padding-left: 10px;
                    .trainer-div {
                        top: 10px;
                        left: 10px;
                        img {
                            height: 50px;
                            width: 50px;
                            margin-right: 10px;
                        }
                        span {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                    button {
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        
                    }
                    .time {
                        margin-bottom: 0x;
                        img {
                            height: 18px;
                            width: 18px;
                            margin-right: 8px;
                        }
                        span {
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }
                    .less-text {
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 19.35px;
                    }
                }
            }
            &:hover {
                .background-img {
                    .description {
                        .more-text {
                            font-size: 18px;
                            line-height: 20px;
                            margin-bottom: 19.35px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.videocard-more-trainer {
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .more-text-respo {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 10px 8px;
        color: #FFFFFF;
        font-family: "din-condensed";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 110%;
        opacity: 1;
        transition: all .3s ease-in 0.2s;
        width: 100%;
        bottom: 20px;
        text-align: center;
    }
    .background-img {
        height: 100%;
        width: 100%;
        position: relative;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
        }
    }
   
}

.trainercard {
    height: 410px;
    max-width: 286.5px;
    cursor: pointer;
    .background-img {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top:40px;
        img {
            max-height: 200px;
            max-width: 200px;
            height: 90%;
            width: 90%;
            border-radius: 50%;;
        }
        .title {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 40px;
            span {
                color: #FFFFFF;
                font-size: 32px;
                letter-spacing: 0;
                line-height: 50px;
                text-align: center;
                @media (min-width: 1080px) and (max-width: 1599.98px) {
                    font-size: 28px;
                    line-height: 40px;
                }
            }
            
        }
        button {
            display: none
        }
    }
    &:hover {
        height: 410px;
        max-width: 286.5px;
        background-color: rgba(255,255,255,0.07);
        button {
            display: flex;
            background-color: transparent;
            border: none;
            box-shadow: none;
            align-items: center;
            margin-top: 50px;
            img {
                height: 20.35px;
                width: 10px;
                margin-left: 10px;
            }
            span {
                color: #FF557B;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
            }
        }
    }
}

.videocard-respo {
    height: 210px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;
    //overflow: hidden;
    .number {
        span {
            color: #1B1B1B;
            font-family: Impact;
            font-size: 120px;
            letter-spacing: 0;
            text-shadow: -10px 10px 15px rgba(251, 251, 251, 0.3), 0 0 2px rgba(251, 251, 251, 1),
             0 0 2px rgba(251, 251, 251, 1), 0 0 2px rgba(251, 251, 251, 1);
        }
        width: 40px;
        
    }  
    .background-img {
        height: 100%;
        width: calc(100% - 40px);
        position: relative;
        transition: all .3s ease-in 0.2s;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
           
        }
        .more-text {
            position: absolute;
            justify-content:center;
            width: 80%;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            color: #ffffff;
            font-size: 16px;
            font-family: "din-condensed";
            letter-spacing: 0;
            line-height: 110%;
            display: flex;
            text-align: center;
        }
    }
}

.videocard-more-respo {
    height: 180px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;  
    .background-img {
        height: 100%;
        width: 100%;
        position: relative;
        transition: all .3s ease-in 0.2s;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
           
        }
        .more-text {
            position: absolute;
            justify-content:center;
            width: 80%;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            color: #ffffff;
            font-size: 16px;
            font-family: "din-condensed";
            letter-spacing: 0;
            line-height: 110%;
            display: flex;
            text-align: center;
        }
    }
}
.trainercard-respo {
    height: 210px;
    max-width: 100%;
    cursor: pointer;
    .background-img {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0;
        img {
            max-height: 200px;
            max-width: 200px;
            height: 90%;
            width: 90%;
            border-radius: 50%;;
        }
        .title {
            width: 80%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            span {
                color: #FFFFFF;
                font-size: 24px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
            
        }
        
    }
   
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
   
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
   
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {

}
/*
// Large devices (desktops, less than 1600px)
@media (min-width: 1080px) and (max-width: 1249.98px) {
    .videocard {
        height: 280px;
        width: 275px;
        .number {
            font-size: 160px;
            line-height: 160px;
        }  
        .background-img {
            height: 100%;
            width: 175px;
            img {
                height: 100%;
                width: 100%;
               
            }
            .description {
                height: 60px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .videocard-more {
        height: 362px;
        width: 100%;
        .background-img {
            .description {
                height: 90px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Large devices (desktops, less than 1600px)
@media (min-width: 1250px) and (max-width: 1399.98px) {
    .videocard {
        height: 310px;
        width: 320px;
        .number {
            font-size: 180px;
            line-height: 180px;
        }  
        .background-img {
            height: 100%;
            width: 220px;
            img {
                height: 100%;
                width: 100%;
               
            }
            .description {
                height: 60px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .videocard-more {
        height: 382px;
        width: 100%;
        .background-img {
            .description {
                height: 80px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Large devices (desktops, less than 1600px)
@media (min-width: 1400px) and (max-width: 1599.98px) {
    .videocard {
        height: 360px;
        width: 370px;
        .number {
            font-size: 200px;
            line-height: 200px;
        }  
        .background-img {
            height: 100%;
            width: 260px;
            img {
                height: 100%;
                width: 100%;
               
            }
            .description {
                height: 60px;
                width: 100%;
                padding-left: 20px;
                .trainer-div {
                    top: 20px;
                    left: 20px;
                    img {
                        height: 60px;
                        width: 60px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 60px;
                        width: 60px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }
                .less-text {
                    font-size: 20px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 20px;
                        line-height: 22px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .videocard-more {
        height: 412px;
        width: 100%;
        .background-img {
            .description {
                height: 80px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
*/