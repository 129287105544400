.image-uploader {
  .images-area {
    width: 50%;
    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
    min-height: 205px;
    position: relative;
    border: 1px dashed rgba(37, 38, 38, 0.4);
    border-radius: 10px;
    padding: 30px;
    display: flex;
    align-items: center;
      justify-content: center;
    .uploaded-images {
      display: flex;
      flex-wrap: wrap;
      max-width: 90%;
      .uploaded-item {
        width: 20%;
        margin-left: 1%;
        margin-right: 1%;
        height: 145px;
        position: relative;
        box-shadow: 0 0 1px #00000059;
        // display: inline-block;

        .image-icons {
          position: absolute;
          top: 8px;
          left: 8px;
          display: flex;

          .image-icon {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background: white;

            & + .image-icon {
              margin-left: 8px;
            }

            &.hero-indicator {
              background: #252626;
              display: none;

              i {
                font-size: 17px;
                line-height: 17px;
                text-align: center;
                color: #f9f9fa;
              }
            }

            &.delete {
              color: red;
              cursor: pointer;

              i {
                color: red;
              }
            }
          }
        }

        img {
          min-width: 40px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }

        &:first-child {
          .image-icons {
            .image-icon {
              &.hero-indicator {
                display: flex;
              }
            }
          }
        }
      }
    }

    label.upload-button {
      width: 67px;
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgba(141, 139, 148, 0.4);
      border-radius: 15px;
      cursor: pointer;
      //position: absolute;
      //top: 67px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      text-align: center;
      color: #252626;

      input[type="file"] {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
        left: -999px;
        right: -999px;
      }

      .full-content {
        display: none;
      }

      &.uploaded-images-0 {
        width: auto;
        min-width: 202px;
        padding-left: 60px;
        padding-right: 60px;
        right: 50%;
        margin-right: -101px;
        // background: white;

        .full-content {
          display: block;

          i {
            margin-right: 5px;
          }
        }

        .content {
          display: none;
        }
      }

      @for $i from 1 through 4 {
        &.uploaded-images-#{$i} {
          left: ($i * 20% + 2%);
        }
      }

      &.uploaded-images-5 {
        display: none;
      }
    }

    &.highlight {
      border: 2px dashed black;
    }

    @media (max-width: 1180px) {
      height: 185px;

      .uploaded-images {
        .uploaded-item {
          height: 125px;
        }
      }
    }

    @media (max-width: 940px) {
      height: auto;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .uploaded-images {
        flex-direction: column;
        row-gap: 10px;

        .uploaded-item {
          width: 100%;
          height: 145px;
        }
      }

      label.upload-button {
        position: static;
        width: 100%;

        &.uploaded-images-0 {
          width: unset;
          min-width: unset;
          margin-right: unset;
        }
      }
    }
  }

  &:not(.multiple) {
    .images-area {
      .uploaded-images {
        justify-content: center;
      }

      label.upload-button {
        &.uploaded-images-1 {
          display: none;
        }
      }
    }
  }

  .helper-text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: rgba(37, 38, 38, 0.4);
    margin-top: 10px;

    i {
      margin-right: 10px;
    }
  }
}

