.rergistration-button {
    box-sizing: border-box;
    height: 68px;
    max-width: 288px;
    width: 40%;
    border: 2px solid #000000;
    box-shadow: 0 9px 13px 3px rgba(0,0,0,0.18);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    &.modal-button {
        border-radius: 0;
        min-width: 288px;
        @media (max-width: 767.98px) {
            max-width: 300px;
            min-width: 47%;
            &.profile-edit {
                min-width: 100%;
            }
        }
    }

    &.green{
        background: transparent;
        box-shadow: none;
        span{
            color: #000000;
            font-family: Impact;
            font-size: 26px;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
            &:hover {
                &:after{
                    content: "";
                    height: 8px;
                    background-color: #000000;
                    display: block;
                    margin: 5px auto 0 0px;
                    border-radius: 2px;
                }
            }
        }
        
    }

    &.black{
        background: #000000;
        box-shadow: none;
        span{
            color: #FFFFFF;
            font-family: Impact;
            font-size: 26px;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
            &:hover {
                &:after{
                    content: "";
                    height: 8px;
                    background-color: #FFFFFF;
                    display: block;
                    margin: 5px auto 0 0px;
                    border-radius: 2px;
                }
            }
        }
       
    }

  

    &.text-button{
        background: transparent;
        box-shadow: none;
        border: none;
        margin-bottom: 80px;
        span{
            font-size: 16.78px;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
            text-decoration-line: underline;
        }
    }

    

    &.text-button-gyik{
        background: transparent;
        box-shadow: none;
        border-radius: 24px;
        border: none;
        width: 218px;
        height: 21px;
        cursor: pointer;
        margin: 0px 10px 10px 10px;
        
    }
    
    &.white{
        background: rgba(255, 255, 255, 0.9);
        box-shadow: none;
        border-radius: 30px;
        border: 1px solid rgba(67, 181, 174, 0.9);
        //width: 351px;
        //height: 67px;
        //padding: 25px 0;
        cursor: pointer;
        margin: 5px 10px;
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            height: 15px;
            text-align: center;
            letter-spacing: 2.5px;
            color: rgba(67, 181, 174, 0.9);
        }
        &:hover {
            background: rgb(88, 154, 151, 0.9);
            span{
                color: #FFFFFF;
            }
        }
    } 
}

.looking-button {
    border-radius: 30px;
    border: none;
    width: 351px;
    //height: 67px;
    padding: 25px 0;
    cursor: pointer;
    margin: 5px 10px;
    &.green{
        background: rgba(67, 181, 174, 0.9);
        box-shadow: none;
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 2.5px;
            color: #FFFFFF;
        }
    }
    
    &.white{
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
        
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 2.5px;
            color: rgba(67, 181, 174, 0.9);
        }
    } 
}

.arrow-button {
    border-radius: 50%;
    border: none;
    width: 50px;
    height: 48px;
    cursor: pointer;
    margin: 5px 10px;
    &.green{
        background: rgba(67, 181, 174, 0.9);
        box-shadow: 0px 2px 2px -2px rgba(31, 27, 45, 0.08), 0px 4px 12px rgba(31, 27, 45, 0.08);
        i {
            color: #FFFFFF;
        }
    }
    
    &.white{
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 2px 9px -2px rgba(31, 27, 45, 0.3);
        
        i {
            color: rgba(67, 181, 174, 0.9);
            width: 9px;
            height: 15.75px;
        }
    } 
}

.text-button-dialog{
    position: absolute;
    top: 10px;
    background: transparent;
    box-shadow: none;
    width: auto;
    height: auto;
    margin: 0 5px 0 0 ;
    padding: 0;
    border: none;
    span{
        color: #5A7184;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &:active {
        border: none;
    }
    &:focus {
        border: none;
    }
    i {
        color: rgba(67, 181, 174, 0.9)!important;
    }
}


  .add-new{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 8px;
    gap: 10px;
    text-transform: initial;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    &:hover {
      color: #fff;
      box-shadow: 0 6px 9px -5px rgba(0,0,0,.3);
    }
    background: rgba(145, 231, 225, 0.9)!important;
    color: #FFFFFF;
    height: 48px;
    width: auto;
    margin: 0;
  }

  .bookmark-button {
    background: transparent;
    box-shadow: none;
    width: auto;
    height: auto;
    //margin: 0 5px 0 0 ;
    //padding: 0;
    border: none;
  }

  .table-icon-btn{
    background-color: transparent!important;
    box-shadow:none;
    color: #686868;
    padding: 0;
    margin: 0;
    width: auto;
    height:auto;
    border-radius: 0;
    img {
      width: 25px;
    }
    &:hover {
      color: #686868!important;
      box-shadow:none;
    }
  }
  .table-search-button {
    background-color: #c8866e;
    position: relative;
    right: 50px;
    top: 0;
    width:50px;
    height:47px;
    border-radius: 0 10px 10px 0;
  }

