.trainer-page {padding-top: 100px;
    h3 {
        margin-bottom: 20px;
    }
    .profile-photo {
        margin-bottom: 50px;
    }
    .trainer-name {
        margin-bottom: 50px;
        span {
            color: #FFFFFF;
            font-size: 40.78px;
            letter-spacing: 0;
            line-height: 50px;
            text-align: center;
        }
    }
    .about-me {
        max-width: 50%;
        margin-bottom: 50px;
        @media (min-width: 1080px) and (max-width: 1300px) {
            max-width: 80%;
          }
        @media (max-width: 1080px) {
            max-width: 100%;
        }
    }
}