.single-image-uploader {
  .images-area {
    width: 100%;
    max-width: 400px;
    height: 205px;
    position: relative;
    border: 1px dashed rgba(37, 38, 38, 0.4);
    border-radius: 10px;
    padding: 30px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.center {
      justify-content: center;
    }

    .uploaded-item {
      width: 145px;
      height: 145px;
      margin-left: 1%;
      margin-right: 25px;
      position: relative;
      // display: inline-block;

      .image-icons {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;

        .image-icon {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;

          & + .image-icon {
            margin-left: 8px;
          }

          i {
            font-size: 20px;
            cursor: "pointer";

            &.fa-trash-alt {
              color: #dc4c64;
            }
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    label.upload-button {
      // width: 193px;
      padding-left: 15px;
      padding-right: 15px;
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgba(141, 139, 148, 0.4);
      border-radius: 15px;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      text-align: center;
      color: #252626;

      input[type="file"] {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
        left: -999px;
        right: -999px;
      }

      i {
        font-weight: 400;
        font-size: 17px;
        line-height: 17px;
        text-align: center;
        color: #252626;
        margin-right: 10px;
      }
    }

    &.highlight {
      border: 2px dashed black;
    }

    @media (max-width: 570px) {
      flex-direction: column;
      height: auto;

      .uploaded-item {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  .helper-text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: rgba(37, 38, 38, 0.4);
    margin-top: 10px;

    i {
      margin-right: 10px;
    }
  }
}

.upload-button-crop {
  // width: 193px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 25px;
  height: 67px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(141, 139, 148, 0.4);
  border-radius: 15px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #252626;

  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    left: -999px;
    right: -999px;
  }

  i {
    font-weight: 400;
    font-size: 17px;
    line-height: 17px;
    text-align: center;
    color: #252626;
    margin-right: 10px;
  }
}
