.client-table {
    background-color: #ffffff;
    padding-top: 20px;
    .form-outline {
        width: 40%;
        margin: 0 0 0 20px;
    }
    .datatable {
        border-top: 1px solid #d2d2d2;
       .datatable-pagination {
        .form-outline {
            width: 100%;
            margin: 0;
        }
       } 
    }
}