.box {
    top: -100px;
    left: -25px;
    width: 107%;
    //height: 500px;
    height: 500px;
    @media (min-width: 990px) and (max-width: 1720px) {
      //height: 455px;
      height: 500px;
    }
    @media (max-width: 990px) {
      //height: 435px;
      height: 435px;
    }
    line-height: 35px;
    padding: 0 5px;
    background-color: rgb(236, 240, 242, 0.9);
    padding-right: 20px;
    border-right: 0;
    position: absolute;
    z-index: 1000;
    transform: rotate(-2deg);
    overflow: hidden !important;
    
  }
  .text-box {
    position: absolute;
    top: 200px;
    //top: 110px;
    @media (min-width: 990px) and (max-width: 1720px) {
      //height: 455px;
      top: 250px;
    }
    @media (max-width: 990px) {
      top: 160px;
      //top: 60px;
    }
    z-index: 1019;
    width: 100%;
    text-align: center;
    .social-title-timer {
        color: rgba(67, 181, 174);
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: 'Impact';
        font-size: 24px;
        @media (max-width: 570px) {
          font-size: 26px;
        }
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.45px; 
        margin-bottom: 50px;
    }
    .social-title {
        color: #0C0C0C;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: 'Impact';
        font-size: 24px;
        @media (max-width: 570px) {
          font-size:26px;
        }
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.45px; 
    }
    
    img {
        width: 55px;
        margin: 25px 15px 0 15px;
    }
}

.kapcsolatok {
    background-color: rgb(0, 0, 0, 0.5);
    .backgroundImage-style {
        position: fixed;
        top: 145px;
        width: 100%;
        object-fit: cover;
        object-position: center 0px;
        z-index: -1;
        margin-top: -150px;
        @media (max-width: 989.9px) {
          top: 276px;
        }
        @media (min-width: 990px) and (max-width: 1720px) {
          top: 276px;
        }
    }
}

.kapcsolatok-form {
    //position: absolute;
    //top: 300px;
    //left: 50%;
    //transform: translate(-50%,0);
    .kapcsolatok-title {
      span {
        color: #ffffff;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: 'Impact';
        font-size: 24px;
        @media (max-width: 570px) {
          font-size: 36px;
        }
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.45px; 
      }
    }
    .kapcsolatok-footer {
      p {
        text-align: center;
        color: #FFFFFF;
        letter-spacing: 0.5px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
        font-weight: 700;
        font-size: 15px;
      }
      .normal-text {
        text-align: center;
        color: #FFFFFF;
        letter-spacing: 0.5px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
        font-weight: normal;
        font-size: 15px;
      }
      .bold-text {
        text-align: center;
        color: #FFFFFF;
        letter-spacing: 0.5px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
        font-weight: 700;
        font-size: 15px;
      }
      span {
        font-style: italic;
        @media (max-width: 570px) {
          //font-size: 36px;
        }
      }
    }
    padding-top: 330px;
    @media (max-width: 990px) {
      //padding-top: 369px;
      padding-top: 400px;
    }
    @media (min-width: 990px) and (max-width: 1720px) {
      //padding-top: 300px;
      padding-top: 300px;
    }
    
    padding-bottom: 50px;
    .modal-title-form {
      color: #464646;
      font-family: "Poppins";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */ 
    }
    input {
      width: 100%;
      //max-width: 535px;
      height: 56px;
      border-radius: 8px;
      border: 1px solid #C3CAD9;
      background: #FFF; 
      outline: none;
      padding: 18px 17px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      color: rgba(37, 38, 38);
    
      
    
      & + input {
        margin-top: 10px;
      }
    
      &.error {
        border-color: #c86e6e;
      }
    
      &[readonly],
      &.disabled {
        color: rgba(37, 38, 38, 0.35);
      }
    
     
      
    
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        border-color: #efe2de !important;
        background-color: #efe2de !important;
        -webkit-box-shadow: 0 0 0 50px #efe2de inset;
      }
    }
    
    label {
      margin-bottom: 5px;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; 
    }
  
    .input-group {
      //max-width: 535px;
      input {
        border-right: none;
      }
      
      .input-group-text {
        height: 56px;
        border-left: none;
        border-radius: 8px;
      }
    }
    textarea {
        width: 100%;
        //max-width: 535px;
        //height: 56px;
        border-radius: 8px;
        border: 1px solid #C3CAD9;
        background: #FFF; 
        outline: none;
        padding: 18px 17px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        color: rgba(37, 38, 38);
      }
    
  
  }
  
