footer.gymfix-footer {
  width: 100%;
  height: 473px; 
  background: #FFFFFF;
  overflow: hidden;
  @media (max-width: 991.98px) {
    height: unset;
  }
  .footer-top {
    height:396px;
    @media (max-width: 991.98px) {
      height: unset;
    }
    padding: 0 65px 0 130.6px;
    display: flex;
    align-items: center;
    @media (max-width: 991.98px) {
      padding: 0;
    }
    
    .footer-col-1 {
      display: flex;
      @media (max-width: 991.98px) {
        padding: 20px 0;
        margin-right: 0;
        justify-content: center;
      }
      img {
        height: 42px;
        margin-right: 134px;
        @media (max-width: 991.98px) {
          margin-right: 0;
          height: 24px;
        }
      }
    }
    .footer-col-2 {
      display: flex;
      .coulom_2 {
        width: 100%;
        display: flex;
        flex-direction: column;

        .iratkozzfel-title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          @media (max-width: 991.98px) {
            justify-content: center;
            margin-bottom: 5px;
          }
          span {
            color: #000000;
            font-size: 26px;
            letter-spacing: 0;
            line-height: 100%;
            @media (max-width: 991.98px) {
              font-size: 20px;
            }
          }
          
         }
         .iratkozzfel-text {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @media (max-width: 991.98px) {
            justify-content: center;
           
          }
          span {
            color: #000000;
            font-family: "din-condensed";
            font-size: 28px;
            letter-spacing: -1px;
            line-height: 115%;
            @media (max-width: 991.98px) {
              text-align: center;
              font-size: 18px;
              max-width: 80%
            }
          }
          
        }
        .email-form {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 25.34px;
          @media (max-width: 991.98px) {
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .email-button {
            margin-left: 27px;
            height: 68px;
            width: 239px;
            box-shadow: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            background: #000000;
            border: none;
            @media (max-width: 991.98px) {
              margin-left: 0;
              height: 40px;
              width: 50%;
              margin-bottom: 15px;
            }
            span {
              color: #FFFFFF;
              font-size: 26px;
              letter-spacing: 0;
              line-height: 110%;
              text-align: center;
              text-transform: initial;
              @media (max-width: 991.98px) {
                font-size: 20px;
              }
              &:hover {
                &:after{
                    content: "";
                    height: 8px;
                    background-color: #FFFFFF;
                    display: block;
                    margin: 0;
                    border-radius: 2px;
                }
            }
            }
          }
          .form-outline{
            display: flex;
            padding: 0;
            justify-content: flex-start;
            align-items: center;
            border-radius: 0;
            height: 68px;
            width: 80%;
            max-width: 575px;
            border: 2px solid #000000;
            background: transparent!important;
            @media (max-width: 991.98px) {
              height: 45px;
              margin-bottom: 10px;
            }
            .form-control {
              height: 68px;
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 45px;
              padding-right: 20px;
              border: 0;
              background: transparent;
              transition: all .2s linear;
              @media (max-width: 991.98px) {
                height: 45px;
              }
            }
        
            .form-control ~ .form-label {
              top: 0;
              left: 0;
              height: 64px;
              padding: 0 0 0 45px;
              display: flex;
              align-items: center;
              pointer-events: none;
              transform-origin: 0 0;
              transition: all .2s ease-out;
              color: #000000;
              font-family: "din-condensed";
              font-size: 22px;
              font-weight: 700;
              letter-spacing: 0;
              @media (max-width: 991.98px) {
                height: 45px;
                font-size: 18px;
              }
            }
            .form-control.active ~ .form-label {
              visibility: hidden;
            }
            .form-control:focus ~ .form-label {
              visibility: hidden;
            }
        
            .form-control ~ .form-notch {
              visibility: hidden;
            }
        
          }
        }
      }
      
      
    }
    
    
  }
  .copyright {
    height: 50px;
    padding: 0 65px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 991.98px) {
      justify-content: center;
      height: 40px;
    }
    span {
      color: #000000;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 26px;
      text-align: right;
      text-decoration-line: underline;
      @media (max-width: 991.98px) {
        font-size: 18px;
      }
    }
  } 
    
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  footer.alakreform-footer {
    height: 340px;  
    
    .footer-top {
      height:270px;
      padding: 0 40px;
  
      .coulom_3 {
        .iratkozzfel-title {
          font-size: 17px;
         }
        
        .email-form {
          .email-button {
            padding: 8px 8px;
            font-size: 12px;
          }
          .form-outline{
            width: 100%;
          }
        }
      }
      
    }
    .copyright {
      height: 70px;
      padding: 0 50px;
    } 
      
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) { 
  footer.alakreform-footer {
    height: 340px;  
    
    .footer-top {
      height:270px;
      padding: 0 40px;
  
      .coulom_3 {
        .iratkozzfel-title {
          font-size: 17px;
         }
        
        .email-form {
          .email-button {
            padding: 8px 8px;
            font-size: 12px;
          }
          .form-outline{
            width: 100%;
          }
        }
      }
      
    }
    .copyright {
      height: 70px;
      padding: 0 50px;
    } 
      
  }
 }