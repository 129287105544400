

.hero {
    //margin-top: -59px;
    @media (max-width: 991.98px) {
        margin-top: 121.6px;
    }
    width: 100%;
    background-color: transparent;
    position: relative;
    z-index: 1;
    &.loggedin {
        @media (max-width: 991.98px) {
            margin-top: 68px;
            
          }
    }

    &.live {
        @media (max-width: 991.98px) {
            margin-top: 170px;
            
          }
    }
    
    .backgroundImage-style {
        width: 100%;
        object-fit: cover;
        object-position: center 0px;
        
    }

    
}

.videotar {
    background: linear-gradient(180deg,transparent 0,#000 3%);
    margin-top: -200px;
    padding-top: 230px;
    padding-bottom: 30px;
    position: relative;
    z-index:10;
    @media (max-width: 991.98px) {
        margin-top: 0;
        margin-top: 200px;
        padding-top: 0;
      }
    .category-title {
        color: #e5e5e5;
        font-family: 'din-condensed';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing:0px;
        padding-left: 0;
        background-color: transparent;
        border: none;
        margin: 0 0 20px 100px;
        @media (max-width: 991.98px) {
            margin: 25px 0 15px 20px;
        }
        .link-text {
            color: #FF557B;
            font-size: 20px;
            opacity: 0;
            transition: all 0.6s ease-in-out;
            display: inline-flex;
            margin-left: 10px;
            &::after {
                margin-left: 10px;
                margin-top: 6px;
                width: 30px;
                flex-shrink: 0;
                content: "";
                background-image: url('../../media/icons/next pink.png');
                background-size: 10px 20.35px;
                background-repeat: no-repeat;  
            }
        }

        &:hover {
            .link-text {
                transform: translate(20px);
                opacity: 1;
                width: 300px;
                font-size: 28px;
            }
        }
        @media (max-width: 991.98px) {
            font-size: 16px
        }

    }
  
}

.gyik {
    background: tranparent; 
    
   
    .gyik-text {
            color: #000000;
            font-family: "din-condensed";
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 120%;
            @media (max-width: 991.98px) {
                font-size: 16px
            }
    }
    .accordion-button {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        &::after {
            flex-shrink: 0;
            margin-left: auto;
            content: "";
            background-image: url('../../media/icons/down.png');
            background-repeat: no-repeat;  
        }
    }
    .accordion-header {
        max-width: 1065px;
        height: 95px; 
        border-radius: 0;
        background: tranparent;
        display: flex;
        align-items: flex;
        @media (max-width: 991.98px) {
            height: 65px; 
        }
        p {
            color: #FFFFFF;
            font-family: Impact;
            font-size: 26.78px;
            letter-spacing: 0;
            line-height: 95px;
            margin-bottom: 0;
            
            @media (max-width: 991.98px) {
                font-size: 18px;
                line-height: 120%;
                max-width: 95%;
            }
        }
    }
    .accordion-flush .accordion-item {
        border-bottom: 2px solid whitesmoke !important;
      }
    .accordion-button:not(.collapsed) {
        max-width: 1065px;
        height: 95px; 
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        @media (max-width: 991.98px) {
            height: 65px; 
        }
        p {
            color: #FFFFFF;
            font-family: Impact;
            font-size: 26.78px;
            letter-spacing: 0;
            line-height: 95px;
            margin-bottom: 0;
            
            @media (max-width: 991.98px) {
                font-size: 18px;
                line-height: 120%;
                max-width: 95%;
            }
        }
    }
    .accordion-body {
        max-width: 1233px; 
        border-radius: 0;
        background: rgba(255, 255, 255, 0.90);
        box-shadow: -2px 6px 15px 0px rgba(0, 0, 0, 0.10);

      }
}
.first-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .first-section-div {
        position: absolute;
        background: transparent;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 80px;
        opacity: 0;
        transition: all .4s ease-in;
        &:hover {
            opacity: 1;
            background: linear-gradient(179.52deg, rgba(0,0,0,0) 0%, #000000 100%);
            transition: all .4s ease-in .8s;
            z-index: 1900;
        }
        .title {
            max-width:550px;
            color: #FFFFFF;
            font-size: 88px;
            letter-spacing: 0;
            line-height: 90px;
            margin-bottom: 15px;
        }
        .top-video {
            display: flex;
            align-items: center;
            margin-bottom: 35px;
            span {
                color: #FFFFFF;
                font-family: "din-condensed";
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 26px;
            }
            img {
                height: 73px;
                margin-right: 29px;
            }
        }
        .description {
            color: #FFFFFF;
            max-width: 479px;
            font-family: "din-condensed";
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 34px;
            margin-bottom: 25px;
        }
        button {
            display: flex;
            align-items: center;
            background: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
            width: auto;
            img {
                width: 111px;
                margin-right: 16.5px;
            }
            span {
                color: #FFFFFF;
                font-family: Impact;
                font-size: 26px;
                letter-spacing: 0;
                line-height: 26px;
            }
        }
        @media (min-width: 1080px) and (max-width: 1249.98px) {
            .title {
                font-size: 44px;
                line-height: 60px;
            }
            .description {
                font-size: 18px;
                line-height: 30px;
            }
            .top-video {
                margin-bottom: 25px;
                span {
                    font-size: 18px;
                    line-height: 22px;
                }
                img {
                    height: 55px;
                    margin-right: 20px;
                }
            }
            button {
                img {
                    width: 80px;
                }
                span {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
        @media (min-width: 1250px) and (max-width: 1399.98px) {
            .title {
                font-size: 48px;
                line-height: 60px;
            }
            .description {
                font-size: 20px;
                line-height: 30px;
            }
            .top-video {
                margin-bottom: 25px;
                span {
                    font-size: 18px;
                    line-height: 22px;
                }
                img {
                    height: 55px;
                    margin-right: 20px;
                }
            }
            button {
                img {
                    width: 80px;
                }
                span {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
        @media (min-width: 1400px) and (max-width: 1599.98px) {
            .title {
                font-size: 62px;
                line-height: 80px;
            }
            .description {
                font-size: 24px;
                line-height: 30px;
            }
            button {
                img {
                    width: 90px;
                }
                span {
                    font-size: 22px;
                    line-height: 24px;
                }
            }
        }
        @media (min-width: 1600px) and (max-width: 1919.98px) {
            .title {
                font-size: 70px;
                line-height: 80px;
            }
            .description {
                font-size: 24px;
                line-height: 30px;
            }
            button {
                img {
                    width: 100px;
                }
                span {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
    }
}

.videodetail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    min-height: 100vh;
    margin-top: 0;
    .black-button {
        position: sticky;
        margin-left: -95vw;
        z-index: 2500;
        left: 0;
        top: 25px;
       
        border: none;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        span {
            font-size: 22px;
            letter-spacing: 0;
            line-height: 26px;
            color: #FFFFFF;
        }
        img {
            height: 18px;
            //width: 34.25px;
            margin-right: 15px;
        }
    }
    
    .first-section {
        .reactplayer-div {
            transform: scale(0);
            opacity: 0;
            transition: all .4s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
        .iframe-div {
            transform: scale(0);
            opacity: 0;
            transition: all .3s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
    }
    .detail-section {
        margin: 20px 0 10px 0;
        padding: 0 10px;
        width: 100%;
        .video-title {
            margin: 0 0 20px 0;
            span {
                color: #FFFFFF;
                font-size: 30px;
                letter-spacing: 0;
                line-height: 110%;
                display: flex;
            }
        }
        .timer {
            align-items: center;
            justify-content: flex-start;
            display: flex;
            margin: 0 0 20px 0;
            img {
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }
            span {
                color: #FFFFFF;
                font-family: "din-condensed";
                font-size: 22px;
                letter-spacing: 0;
                line-height: 34px;
                text-align: center;
            }
        }
        .play-button {
            margin: 0 0 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                border: none;
                background-color: transparent;
                padding: 0;
                img {
                    width: 60px;
                }
            }
        }
        .video-description {
            margin: 0 0 20px 0;
            span {
                color: #FFFFFF;
                font-family: "din-condensed";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
        }
        .trainer-div {
            margin: 0 0 20px 0;
            display: flex;
            align-items: center;
            img {
                width: 100px; 
                border-radius: 50%;
                margin-right: 15px;
            }
            span {
                color: #FFFFFF;
                font-family: "din-condensed";
                font-size: 26px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
        }
        .bookmark-div {
            margin: 30px 0 20px 0;
            span {
                color: #FFFFFF;
                font-family: "din-condensed";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
            .bookmark-button {
                img {
                    width: 20px;
                    margin-right: 15px;
                }
            }
        }
    }
    .videolist-section {
        margin: 20px 0 10px 0;
        padding: 0 10px;
        width: 100%;
        overflow: hidden;
        .videolist-title {
            margin: 0 0 20px 0;
            span {
                color: #FFFFFF;
                font-family: "din-condensed";
                font-size: 26px;
                letter-spacing: 0;
                line-height: 110%;
            }
            .trainer {
                display: flex;
                align-items: center;
                margin-top: 20px;
                img {
                    border-radius: 50%;
                    width: 80px;
                    margin-right: 15px;
                }
            }
        }
    }
}

.videoplay-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-height: 100vh;
    button {
        position: absolute;
        z-index: 2500;
        left: 20px;
        top: 20px;
        border: none;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        span {
            font-size: 26px;
            letter-spacing: 0;
            line-height: 26px;
            color: #FFFFFF;
        }
        img {
            height: 30px;
            //width: 34.25px;
            margin-right: 20px;
        }
    }
    .first-section {
        .reactplayer-div {
            transform: scale(0);
            opacity: 0;
            transition: all .4s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
        .iframe-div {
            transform: scale(0);
            opacity: 0;
            transition: all .3s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
    }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
   
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
   
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .videotar {
        background: linear-gradient(180deg, transparent 0, #000 3%);
        margin-top: -150px;
        padding-top: 200px;
        padding-bottom: 30px;
        position: relative;
        z-index: 10;
    }
   
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) { 

}




